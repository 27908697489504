
import Vision from "./pages/Vision";
import {Routes,Route} from 'react-router-dom';
import Home from './pages/Home';

import Services from './pages/Services';
import Associate from "./pages/Associate";
import Projects from "./pages/Projects";
import Client from "./pages/Client";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";

function App() {
  return (
   <>

   <Routes>
    <Route exact path='/' element={<Home />} />
        <Route  path='/Vision' element={<Vision />} />
        <Route  path='/Services' element={<Services />} />
        <Route  path='/Associate' element={<Associate />} />
        <Route  path='/Projects' element={<Projects />} />
        <Route  path='/Client' element={<Client />} />
        <Route  path='/Contact' element={<Contact />} />
       
  </Routes>
  
 
    <Footer/>
 
   </>
  );
}

export default App;
