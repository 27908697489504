import React from 'react';

export default function Banner() {
  return (
    <div className="bg-[#f3f4f6] bg-[url(https://mediacdn.acciona.com/media/etwborpj/dsc_3692-1.jpg)] w-full py-[300px] pt-[50px] bg-no-repeat bg-cover ">
      <div className="max-w-[1240px] mx-auto text-center ">
        <div className="font-bold text-4xl italic">AR AQUAFORMATICS</div>
        <div className="text-white mt-7 mb-9 font-bold text-2xl">
          AR Aquaformatics as one of the quality leader dedicated in water and
          waste water system and services provider, backed by In-house
          engineering and Biotechnology of Rotech Biotechnology. With the most
          extensive range of technologies, products and services within a single
          company, we provide solutions to “Total water Management and resource
          recovery needs".
        </div>
        <div>
          <div className='text-white font-bold'>
            Our range of products includes water, waste water treatment and
            recycling plants using various physio-chemical processes for
            settling, clarification, filtration, disinfections, membranes and
            ion exchange technology, ion exchange resins; polymers and
            polyelectrolyte for water and non-water processes; boiler, cooling
            water and other related treatment chemicals. We also offer technical
            services such as project management at site, design, erection and
            commissioning, operation and maintenance of plant, and supply of
            spares and components
          </div>
        </div>
      </div>
    </div>
  );
}
