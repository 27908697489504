import React from "react";

import Header from "../components/Header";

export default function Client() {
    return (
        <>
        <Header/>
        <div className="bg-blue-500  w-full  md:h-[1000px] bg-no-repeat bg-cover bg-center pb-1">
        <div className='table-row-group'>
        <div className='inline-grid p-14 shadow-2xl hover:scale-90 duration-150'>
        <div className="w-full p-4 shadow-md lg:max-w-lg bg-white h-[800px]  hover:shadow-2xl transition">
            <div className="space-y-2">
                <h3 className="text-2xl font-semibold text-center ">
                ETP/STP
                </h3>
                <p className="text-gray-600 text-sm md:text-lg">
                    <li>Sudha Dairy, Patna (Bihar)- ETP - 400 KLD</li>
                    <li>Sudha Dairy, Hajipur (Bihar) - ETP- 150 KLD</li>
                    <li>Meghana Dairy, Kolkata- ETP Bioaugmentation</li>
                    <li>India Gate- STP-100 KLD</li>
                    <li>DDA Pocket 1 A- 1 MLD STP</li>
                    <li>MANESAR Dairy, Gurgaon- 100 KLD</li>
                    <li>Coke Dasna, U.P. - ETP - Bioaugmentation</li>
                    <li>Arihant Pulp & Papers Pvt. Ltd., Muzaffarnagar - Retrofitting ETP - 100 KLD</li>
                    <li>Coke Dasna, U.P. - ETP - Bioaugmentation</li>
                    <li>Daawat Rice - Consultancy & Retrofitting</li>
                    <li>EIH LTD., Gurgaon - Retrofitting ETP - 36 KLD</li>
                    <li>Katyayini Paper Mill, Kashipur Consultancy & Retrofitting - 2.5 MLD ETP.</li>
                    <li>Mahalaxmi Malt, Palwal - Retrofitting ETP - 36 KLD</li>
                    <li>Salasaar Steels, Hapur - Retrofitting ETP - 30 KLD</li>
<li>Sahara Mall Gurgaon - Consultancy STP</li>
<li>Afflatus International- STP- 60 KLD</li>
<li>Afflatus International- ETP- 100 KLD </li>
                </p>
            </div>
        </div>
        </div>
        <div className='inline-grid p-14 shadow-2xl hover:scale-90 duration-150 '>
         <div className="w-full p-4 lg:max-w-lg bg-white md:h-[800px] hover:shadow-2xl transition ">
         <div className="space-y-2 ">
             <h3 className="text-2xl font-semibold text-center">
             RO Plant
             </h3>
             <p className="text-gray-600 ">
             <li>Aquafresh, Gurgaon - RO PLANT - 1,000 LPH & 5,000 LPH</li>
             <li>BPS University, Sonipat - RO Plant - 5,000 LPH</li>
             <li>EIH Ltd, Gurgaon- RO Plant- 1000 LPH</li>
             <li>Life Line Aqua, Bihar - RO Plant - 500 LPH</li>
             <li>Mapple Hotel, New Delhi - Retrofitting, WTP & RO Plant - 1,000 LPH</li>
             <li>Padmavati Agrico India (P) Ltd, Ajmer - RO Plant - 3,000 LPH</li>
             <li>PGI Hospital, Sonipat - RO Plant - 5,000 LPH</li>
             <li>Purlieus Inc., Noida - RO Plant - 3,000 LPH</li>
             <li>Rimjhim Ispat, Kanpur- RO Plant- 15,000 LPH</li>
             <li>Sharma Waters, Greater Noida - RO Plant - 1,000 LPH</li>
             <li>Secure Beverages Industries Pvt Ltd, Bhiwadi - RO Plant - 10,000 LPH</li>
             <li>SMS Envocare Ltd- RO Plant- 650 LPH</li>
<li>Tyagi & Sons, Ghaziabad - RO Plant - 3000 LPH</li>
<li>Virgin Envirotech Pvt Ltd, Sahibabad- 3000 LPH</li>
<li>J. J. ENTERPRISES, Patna- 10,000 LPH</li>
<li>B.G. Shirke Constructions Pvt Ltd- 15 Plants of 3000 LPH</li>
<li>B.G. Shirke Constructions Pvt Ltd- 12 Plants of 500 LPH</li>
<li>Aquiver water- 3 plants of 3000 LPH</li>
<li>Flora Wastewater Treatment Pvt Ltd- 8 plants of 250 LPH</li>
<li>Shapoorji Paulonji Gurgaon: 300 m3 WTP</li>
<li>ESPL Jammu: 200 m3/day WTP</li>
<li>Sterlin & Wilson: 20 M3 RO Plant & Solar Water Heating</li>
<li>Jawahar Lal Bhawan: AMC RO Plant</li>
<li>Sena Bhawan, New Delhi- RO PLant</li>
             </p>
         </div>
     </div>
     </div>
     </div>
     </div>
    
     </>
    );
}