import React from "react";

import Header from "../components/Header";
import vision from "../img/vision.jpg";

export default function Vision() {
  return (
    <>
      <Header />
      <div className="py-2 px-4 bg-blue-500">
        <div className="max-w-[1250px] mx-auto  my-12 md:grid grid-cols-2 gap-8">
          <div className=" col-span-1 w-[100%] flex flex-col justify-center  ">
            <img src={vision} alt="" />
          </div>
          <div className=" col-span-1  flex flex-col justify-center p-2 font-bold">
            <div className="border-solid border-2  rounded-lg text-center py-3 my-3 p-1 transition-shadow ease-in-out duration-300 shadow-none hover:shadow-2xl bg-cyan-600">
              {" "}
              Our one and only rule is based on the satisfaction of customers by
              “creating the difference in the similarities”.
            </div>
            <div className="border-solid border-2  rounded-lg text-center py-3 my-3 transition-shadow ease-in-out duration-300 shadow-none hover:shadow-2xl p-1 bg-cyan-600">
              Utter and cost-effective solution so that client can accomplish
              their objective of pollution free environment and can recycle the
              maximum waste water into their plant which is very much required
              in the present scenario.
            </div>
            <div className="border-solid border-2  rounded-lg text-center py-3 my-3 transition-shadow ease-in-out duration-300 shadow-none hover:shadow-2xl p-1 bg-cyan-600">
              Social awareness to the clients to make them understand the
              benefits and the return of investment through environment.
            </div>
            <div className="border-solid border-2  rounded-lg text-center py-3 my-3 transition-shadow ease-in-out duration-300 shadow-none hover:shadow-2xl p-1 bg-cyan-600">
              To provide the full technical support for the operational problems
              which are countenance during the operations of the plant.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
