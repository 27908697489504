import React from 'react'

import Header from '../components/Header'
import team from '../img/team1.jpg'

export default function Associate() {
  return (
    <>
    <Header/>
   {/* <div style={{ backgroundImage:`url(${associate})`,backgroundRepeat:"no-repeat",backgroundSize:"contain", 
    height:1240,width:1240 }}>

  </div>*/}
  <div className='bg-[url(https://cdn.pixabay.com/photo/2015/11/26/07/47/hands-1063442_960_720.jpg)]  w-full h-screen bg-no-repeat bg-cover bg-center pb-1'>
  <div className='max-w-[1200px] mx-auto h-[200px]  pt-[50px] grid grid-cols-2  gap-40'>
  
  <div className='rounded-full font-bold italic text-base md:text-xl h-[100px] flex justify-center items-center bg-[#6366f1] shadow-2xl hover:scale-125 duration-100'>Roebic Biotechnology </div>

  <div className='rounded-full font-bold italic text-base md:text-xl h-[100px] flex justify-center items-center bg-[#6366f1] shadow-2xl hover:scale-125 duration-100'> Rotech Biotechnology</div>
 
  </div>
  <div className='max-w-[300px] mx-auto h-[200px]  grid grid-cols-1  gap-40'>
  <div className='h-[200px] h-[200px] row-span-1' >
  <div className="  rounded-lg shadow-2xl lg:flex lg:w-[300px] h-[200px] shadow-1 cursor-pointer hover:shadow-2xl transition  rounded-lg rounded-tl-[90px] ">
  
            <img
                className="object-cover w-full lg:w-[300px]  lg:h-[200px] rounded-tl-[90px] hover:shadow-2xl transition"
                 src = {team} alt="" 
                 />
         
         </div>
  </div>
  </div>

  <div className='max-w-[1200px] mx-auto h-[200px] pt-[50px] grid grid-cols-2  gap-40'>
  <div className='rounded-full font-bold italic text-base md:text-xl h-[100px] flex justify-center items-center bg-[#6366f1] shadow-2xl hover:scale-125 duration-100'>Macro Services </div>
  <div className='rounded-full font-bold italic text-base md:text-xl h-[100px] flex justify-center items-center bg-[#6366f1] shadow-2xl hover:scale-125 duration-100'> Parivartan Inc.</div>
  </div>
  </div>
  
    </>
  );
}

