import React from 'react'

import Header from '../components/Header';
import one from  '../img/1.jpg'
import two from  '../img/2.jpg'
import three from  '../img/3.jpg'
import four from  '../img/4.jpg'
import five from  '../img/5.jpg'
import six from  '../img/6.jpg'
import seven from  '../img/7.jpg'
import eight from  '../img/8.jpg'
import nine from  '../img/9.jpg'




export default function Projects() {
  return (
    <>
    <Header/>
    <div className='bg-blue-500'>
    <div className='table-row-group'>
    <h className="font-bold text-4xl underline decoration-dotted item-center ">STP PLANT TECHNOLOGY</h>
    </div>
     <div className='inline-grid p-12'>
    <div className='bg-white shadow-1 p-5 rounded-lg rounded-tl-[90px] w-full max-w-[352px] mx-auto cursor-pointer  hover:shadow-2xl transition  '>
      <img className='mb-8 rounded-tl-3xl rounded-br-3xl' src={one} alt='' />
      <div className='mb-4 flex gap-x-2 text-xl justify-center font-bold text-[#16a34a]'>
      MBBR based STP Plants</div>
      <p className=' text-black px-3 inline-block text-center'>
        
         MBBR based STP plants use a biofilter and suspended growth from treating wastewater, and use tank volume for biomass growth. The carriers are designed to hold the biofilm, and air bubbles move them along during agitation. Depending on the size and filling of the carriers, the MBBR reactor can be of a different shape and size, and it can support varying loads. It is a highly effective process and depends on the characteristics of the wastewater.
        </p>
       
        </div>
        </div>

    <div className='inline-grid p-12'>
    <div className='bg-white shadow-1 p-5 rounded-lg rounded-tl-[90px] w-full max-w-[352px] mx-auto cursor-pointer hover:shadow-2xl transition '>
      <img className='mb-8 rounded-tl-3xl rounded-br-3xl' src={two} alt='' />
      <div className='mb-4 flex gap-x-2 text-xl justify-center font-bold text-[#16a34a]'>
      MBR based STP Plants</div>
      <p className=' text-black px-3 inline-block text-center'>
        
        MBR are based STP Plant is a membrane-based sludge treatment processes. The primary focus of these systems is to improve fluxes while decreasing fouling. However, their poor economics and limited applications resulted in their use in niche applications. In order to increase the efficiency of the process, MBRs are being used more frequently. The main benefits of MBRs include the ability to remove pollutants from wastewater and improved water quality.
        </p>
      
        </div>
        </div>
    
        <div className='inline-grid p-12'>
    <div className='bg-white shadow-1 p-5 rounded-lg rounded-tl-[90px] w-full max-w-[352px] mx-auto cursor-pointer hover:shadow-2xl transition  '>
      <img className='mb-8 rounded-tl-3xl rounded-br-3xl' src={three} alt='' />
      <div className='mb-4 flex gap-x-2 text-xl justify-center font-bold text-[#16a34a]'>
      SBR based STP Plants</div>
      <p className=' text-black px-3 inline-block text-center'>
      SBR based STP plant are a popular choice for large-scale wastewater treatment. These plants typically have one tank and are used for multiple aspects of wastewater treatment. These plants can treat a population of one million people. Many cities and municipalities are now using SBR-based STP plants to expand the capacity of existing facilities. This article will provide a brief overview of SBR sludge treatment and its benefits.
       
        </p>
        </div>
        </div>

        <div className='table-row-group'>
    <h className="font-bold text-4xl underline decoration-dotted item-center ">OUR PRODUCTS RANGE</h>
    </div>
     <div className='inline-grid p-12'>
    <div className='bg-white shadow-1 p-5 rounded-lg rounded-tl-[90px] w-full max-w-[352px] mx-auto cursor-pointer hover:shadow-2xl transition  '>
      <img className='mb-8 rounded-tl-3xl rounded-br-3xl' src={four} alt='' />
      <div className='mb-4 flex gap-x-2 text-xl justify-center font-bold text-[#16a34a]'>
      1 to 10 KLD STP Plants</div>
      <p className=' text-black px-3 inline-block text-center'>
        
        There are a variety of options for One to Ten KLD STP plants. These systems include a primary sand filter, multi-grade sand filter, and MBBR media. MBBR treatment plants may also contain a chlorine dosing system, a sludge recirculation pump, and a filter press. A wastewater treatment plant can be designed to meet the requirements of a specific process.
        </p>
        </div>
        </div>

    <div className='inline-grid p-12'>
    <div className='bg-white shadow-1 p-5 rounded-lg rounded-tl-[90px] w-full max-w-[352px] mx-auto cursor-pointer hover:shadow-2xl transition '>
      <img className='mb-8 rounded-tl-3xl rounded-br-3xl' src={five} alt='' />
      <div className='mb-4 flex gap-x-2 text-xl justify-center font-bold text-[#16a34a]'>
      10 to 50 KLD STP Plants</div>
      <p className=' text-black px-3 inline-block text-center'>
        
         There are many benefits of installing a 10 KLD TO 50KLD STP plant. It is designed to reduce the sewage volume, and it helps control power consumption by the STP. STPs are also more reliable than conventional septic systems, and they require less maintenance. However, a properly installed STP may have a high initial investment.
       
        </p>
        </div>
        </div>
    
        <div className='inline-grid p-12'>
    <div className='bg-white shadow-1 p-5 rounded-lg rounded-tl-[90px] w-full max-w-[352px] mx-auto cursor-pointer hover:shadow-2xl transition  '>
      <img className='mb-8 rounded-tl-3xl rounded-br-3xl' src={six} alt='' />
      <div className='mb-4 flex gap-x-2 text-xl justify-center font-bold text-[#16a34a]'>
      50 to 100 KLD STP Plants</div>
      <p className=' text-black px-3 inline-block text-center'>
         STP plants have become one of the most important elements of residential development, and they provide a safe environment for residents and are the preferred treatment method. The new technology for STPs is a highly efficient, and it can treat 50 to 100 KLD of sewage. STPs are typically placed above ground to allow proper airflow and easy maintenance.
         </p>
        </div>
     
        </div>

        <div className='inline-grid p-12'>
    <div className='bg-white shadow-1 p-5 rounded-lg rounded-tl-[90px] w-full max-w-[352px] mx-auto cursor-pointer hover:shadow-2xl transition '>
      <img className='mb-8 rounded-tl-3xl rounded-br-3xl' src={seven} alt='' />
      <div className='mb-4 flex gap-x-2 text-xl justify-center font-bold text-[#16a34a]'>
      100 to 500 KLD STP Plants</div>
      <p className=' text-black px-3 inline-block text-center'>
        
         There are many STP plants, including MBBR, SBR, Activated sludge, and MBR. Whether you want to treat wastewater for reuse or release it back into the environment, there is a sewage treatment plant that is right for you. Contact us to know more about these types of plants. And dont forget to ask your local wastewater company about their STP plants.
        </p>
        </div>
        </div>
           <div className='inline-grid p-12'>
    <div className='bg-white shadow-1 p-5 rounded-lg rounded-tl-[90px] w-full max-w-[352px] mx-auto cursor-pointer hover:shadow-2xl transition '>
      <img className='mb-8 rounded-tl-3xl rounded-br-3xl' src={eight} alt='' />
      <div className='mb-4 flex gap-x-2 text-xl justify-center font-bold text-[#16a34a]'>
      500 to 1000 KLD STP Plants</div>
      <p className=' text-black px-3 inline-block text-center'>
        
         If you want to know installing a 1000 KLD or 500 KLD STP plant. The next advantage is that the entire process will be more efficient, and the final result will be cleaner water. The most important feature of an STP is that it can produce zero liquid discharge. However, this process is complicated and requires a substantial initial investment.
         </p>
       </div>
       </div>
        <div className='inline-grid p-12'>
    <div className='bg-white shadow-1 p-5 rounded-lg rounded-tl-[90px] w-full max-w-[352px] mx-auto cursor-pointer hover:shadow-2xl transition '>
      <img className='mb-8 rounded-tl-3xl rounded-br-3xl' src={nine} alt='' />
      <div className='mb-4 flex gap-x-2 text-xl justify-center font-bold text-[#16a34a]'>
      1000 KLD And More STP Plants</div>
      <p className=' text-black px-3 inline-block text-center'>
        
        The STP process has evolved to include both biological and chemical processes. This process is the most efficient treatment method, ensuring that it is free of contaminants and safe for consumption. They are more affordable and environmentally friendly. Here are some of the benefits of 1000 And More KLD STP Plants.
        </p>
        </div>
        </div>
        </div>
        

    
        
    </>
  );
}
