import React from 'react';

const Footer = () => {
  return (
    <footer className='bg-black py-8 text-center text-white absolute w-full'>
      <div className='container mx-auto justify-center'>
        <div className='inline-block justify-center  flex space-x-4'>
 <span >Privacy Policy   </span>
 <span > Company Brochure </span>
 <span > Terms & Conditions</span>
</div>
<div className='justify-center py-4'>
 &copy; 2022. All rights reserved.
 </div>
      </div>
    </footer>
  );
};

export default Footer;
