import React, { useState }from 'react'
import {AiOutlineMenu,AiOutlineClose} from 'react-icons/ai'
import {Link} from 'react-router-dom'

export default function Header() {
    const [toggle,setToggle] = useState(false);
  return (
    <div className='bg-[#f3f4f6] py-4'>
        <div className='max-w-[1240px] py-[10px] mrl-1  items-center  flex justify-between  mx-auto  '> 
        <div class="flex flex-wrap justify-center">
        
            <img src='https://aquaformatics.com/static/media/companyLogo.3488ba49.jpg'
            class="max-w-xs h-auto transition-shadow ease-in-out duration-300 shadow-none hover:shadow-xl ml-1"
            alt=""></img>

        </div>
        {
            toggle ?
            <AiOutlineClose onClick={() => setToggle(!toggle)} className="text-black text-5xl lg:hidden block ml-1"/>
            
            :
            <AiOutlineMenu onClick={() => setToggle(!toggle)} className="text-black text-5xl lg:hidden block ml-1"/>
          
            
        }
       
        
        <ul className='hidden lg:flex text-[#0ea5e9] gap-6 text-base font-bold italic '>
            <li className='hover:text-blue-700'>
            <Link to = "/">
                Home
                </Link>
            </li>
            <li className='hover:text-blue-700'>
              <Link to = "/Vision">
               Our Vision
               </Link>
            </li>
            <li className='hover:text-blue-700'>
            <Link to = "/Services">
               Services
               </Link>
            </li>
            <li className='hover:text-blue-700'>
            <Link to = "/Associate">
                Associate Companies
                </Link>
            </li>
            <li className='hover:text-blue-700'>
            <Link to = "/Projects">
              Our Projects
              </Link>
            </li>
            <li className='hover:text-blue-700'>
            <Link to = "/Client">
               Client
               </Link>
            </li>
            <li className='hover:text-blue-700'>
            <Link to = "/Contact">
                Contact Us
               </Link> 
            </li>
        </ul>
        {/*responsive menu*/}
        <ul className= {`duration-300 top-[110px] lg:hidden w-full min-h-screen text-[#0ea5e9] text-base font-bold italic fixed bg-black  
             ${toggle ? 'left-[0]' : 'left-[-100%]'} 
             `}>
                <li className='p-6'>
                <Link to = "/">
                Home
                </Link>
                </li>
            <li className='p-6'>
            <Link to = "/Vision">
               Our Vision
                </Link>
            </li>
            <li className='p-6'>
            <Link to = "/Services">
               Services
               </Link>
            </li>
            <li className='p-6'>
            <Link to = "/Associate">
                Associate Companies
                </Link>
            </li>
            <li className='p-6'>
            <Link to = "/Projects">
              Our Projects
              </Link>
            </li>
            <li className='p-6'>
            <Link to = "/Client">
               Client
               </Link>
            </li>
            <li className='p-6'>
            <Link to = "/Contact">
                Contact Us
            </Link>
            </li>
        </ul>
        </div>
    </div>
  )
}
