import React from "react";


import Header from "../components/Header";

export default function Services() {
    const posts = [
       
        
        {
            title: "Bio augmentation",
          
        },
        {
            title: "Turnkey Projects",
           
        },
        {
            title: "Modification3",
            
        },
        {
            title: "grease trap maintenance",
           
        },
        {
            title: "Packaged STP",
           
        },
        {
            title: "Chemical Supply",
           
        },
        {
            title: "Technical Consultancy services",
           
        },
        {
            title: "ETP/STP equipments",
           
        },
        {
            title: "Operation & Maintenance of ETP/STP",
           
        },
       
    ];
    return (
        <>
        <Header/>
        
         <div className=" flex flex-col justify-center text-center p-10 font-bold bg-blue-500 w-full h-full">
         <h className="font-bold text-4xl underline decoration-dotted  ">Our Services</h>
         <hr class="my-6  h-1 bg-red-500 rounded border-0 md:my-8 dark:bg-red-500"></hr>
       
            <div className="grid gap-5 lg:grid-cols-4 m-3 transition hover:transition-all ">
                {posts.map((items, key) => (
                    <div className="w-full rounded-lg shadow-md lg:max-w-sm p-2  transition ease-in-out delay-150 bg-white hover:-translate-y-1 hover:scale-110 hover:bg-rose-600 duration-300  " key={key}>
                    
                       
                        <div className="p-4">
                            <h4 className="text-xl font-semibold text--600 text-center">
                                
                                {items.title}
                            </h4>
                            <p className="mb-2 leading-normal">
                            {items.content}
                            </p>
                           
                        </div>
                    </div>
                ))}
            </div>
            </div>
         
        </>
    );
 
}
  

