import React, { useState } from 'react';

import Header from '../components/Header';

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = await fetch('https://mailer-api-phi.vercel.app/mail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          message,
        }),
      });
      alert('Thank you for your feedback');
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
    } catch (error) {
      console.log(error);
      alert('Something went wrong');
    }
    setLoading(false);
  };
  return (
    <>
      <div>
        <Header />
      </div>
      <div className="bg-blue-500 w-full h-[1170px]  md:h-screen  bg-cover bg-center pt-1  ">
        <div className="max-w-[1240px] h-[500px] mx-auto pt-[2px] md:pt-[20px] p-5 md:gap-3  my-10 md:grid grid-cols-2 hover:scale-900 duration-100">
          <div className="  h-[500px] bg-[white] col-span-1 md:w-[70%] justify-center py-5 mt-6 text-justify mb-6  rounded-lg rounded-tl-[120px] rounded-br-[120px] ">
            <h1 className="font-bold text-center text-4xl p-6 underline">
              {' '}
              CONTACT US
            </h1>
            <div className="text-center space-y-6">
              <div>+919911498738,</div>
              <div> 7982361289</div>
              <div> araquaformatics@gmail.com</div>
            </div>
            <h1 className="font-bold text-center text-4xl p-4 underline">
              {' '}
              FOLLOW US
            </h1>
            <div className="text-center space-y-4">
              <div>Instagram</div>
              <div> Twitter</div>
              <div> Linkdin</div>
              <div>Youtube</div>
            </div>
          </div>

          <div className="  h-[440px]   col-span-1 ">
            <div className="grid grid-cols-1 sm:grid-cols-1  w-[100%] h-[100%]   hover:shadow-2xl transition  ">
              <div className="hidden sm:block"></div>

              <div className="bg-gray-800 flex flex-col justify-center h-[100%] rounded-lg rounded-tl-[120px] rounded-br-[120px] ">
                <form className="max-w-[400px] w-full mx-auto rounded-lg bg-gray-900 p-8 px-8 rounded-lg rounded-tl-[120px] rounded-br-[120px] hover:shadow-2xl transition ">
                  <h2 className="text-4xl dark:text-white font-bold text-center">
                    REVIEW US
                  </h2>
                  <div className="flex flex-col text-gray-400 py-2">
                    <label>Name</label>
                    <input
                      className="rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
                      type="text"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                  </div>
                  <div className="flex flex-col text-gray-400 py-2">
                    <label>Phone</label>
                    <input
                      className="p-2 rounded-lg bg-gray-700 mt-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
                      type="number"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      value={phone}
                    />
                  </div>
                  <div className="flex flex-col text-gray-400 py-2">
                    <label>Email</label>
                    <input
                      className="p-2 rounded-lg bg-gray-700 mt-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
                      type="text"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                    />
                  </div>
                  <div className="flex flex-col text-gray-400 py-2">
                    <label>Your Message</label>
                    <input
                      className="p-2 rounded-lg bg-gray-700 mt-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
                      type="text"
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      value={message}
                    />
                  </div>
                  <button
                    className="w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded-lg"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
